import * as Yup from "yup";
import { CourseInput } from "./../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: CourseInput = {
  lessons: [],
  title: "",
  description: "",
  themes: null,
  axes: null,
  tags: null,
  grade_id: 3,
  type: "",
  nem: false,
  program_2017: false,
  program_2526: false,
  english: false,
  reading: false,
  hide_course_for_students: false,
  subject_id: 1,
  threemester: null,
  threemester_2017: null,
  threemester_2526: null,
  threemester_english: null,
  threemester_reading: null,
  month: null,
  sep_book_pages: "",
  sep_book_pages_2017: "",
  sep_book_pages_2526: "",
  sep_book_pages_english: "",
  sep_book_pages_reading: "",
  week: null,
  week_2017: null,
  week_2526: null,
  week_english: null,
  week_reading: null,
  lessons_2425: [],
  lessons_2526: [],
};

export const NewUsersSchema = Yup.object().shape({
  title: Yup.string().required("El titulo requerido."),
  description: Yup.string().required("La descripcion requerido."),
  type: Yup.string().required("El tipo es requerido."),
});
