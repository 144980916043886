import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateCourseVariables } from "../__generated__/gql-types/UpdateCourse";

const UseUpdateCourses = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateCourseVariables) => {
      return await client.mutate({
        mutation: UPDATE_COURSE,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateCourses;

const UPDATE_COURSE = gql`
  mutation UpdateCourse($updateCourseId: ID!, $courseInput: CourseInput) {
    updateCourse(id: $updateCourseId, courseInput: $courseInput) {
      id
      title
      lessons {
        title
        id
        course_id
      }
      grade {
        name
        id
      }
    }
  }
`;
