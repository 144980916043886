import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./coursesForm.module.css";
import { Buttons } from "../..";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Col, notification, Row, Select } from "antd";
import { initialValuesObj } from "./coursesForm.types";
import UseCreateCourses from "../../../api/useCreateCourses";
import UseUpdateCourses from "../../../api/useUpdateCourses";
import UseGetCourseByIdBackOffice from "../../../api/useGetCourseByIdBackOffice";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { CourseInput } from "./../../../__generated__/gql-types/globalTypes";
import {
  GetCourses_getCourses,
  GetCourses_getCourses_lessons,
} from "../../../__generated__/gql-types/GetCourses";
import { GetGrade_getGrades } from "../../../__generated__/gql-types/GetGrade";
import { GetSubjects_getSubjects } from "./../../../__generated__/gql-types/GetSubjects";
import { GetAxes_getAxes } from "../../../__generated__/gql-types/GetAxes";
import { GetThemes_getThemes } from "../../../__generated__/gql-types/GetThemes";
import { GetTags_getTags } from "../../../__generated__/gql-types/GetTags";
import { ActivityIndicator } from "../../ActivityIndicator";
import { useAxes } from "../../../hooks/useAxes";
import { useSubject } from "../../../hooks/useSubject";
import { useLessons } from "../../../hooks/useLessons";
import { useGrades } from "../../../hooks/useGrades";
import { useThemes } from "../../../hooks/useThemes";
import { useCourses } from "../../../hooks/useCourses";
import { useDispatch } from "react-redux";
import { useTags } from "../../../hooks/useTags";

const { Option } = Select;

const NewCourses: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { axes, loading: loadingAxes } = useAxes();
  const { themes: theme, loading: loadingThemes } = useThemes();
  const { subjects, loading: loadingSubjects } = useSubject();
  const { lessons: lesson, loading: loadingLessons } = useLessons();
  const { grades: grade, loading: loadingGrades } = useGrades();
  const { courses, loading: loadingCourses } = useCourses();
  const { tags, loading: loadingTags } = useTags();
  const [lessonSelected, setLessonSelected] = useState<
    GetCourses_getCourses_lessons[]
  >([]);
  const [lessonSelected2425, setLessonSelected2425] = useState<
    GetCourses_getCourses_lessons[]
  >([]);
  const [lessonSelected2526, setLessonSelected2526] = useState<
    GetCourses_getCourses_lessons[]
  >([]);
  const [initialValues, setInitialValues] =
    useState<CourseInput>(initialValuesObj);
  const [loading, setLoading] = useState(false);

  const getCourseById = UseGetCourseByIdBackOffice();
  const createCourse = UseCreateCourses();
  const updateCourse = UseUpdateCourses();
  const navigate = useNavigate();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (id) {
        const courseData = await getCourseById({ courseId: id });
        if (courseData?.lessons && courseData?.lessons.length > 0) {
          let lessonsSerialized = courseData?.lessons.sort(
            (a, b) => a.order_by! - b.order_by!
          );
          setLessonSelected(lessonsSerialized);

          if (courseData?.nem) {
            setLessonSelected2425(lessonsSerialized);
          }
          if (courseData?.program_2526) {
            setLessonSelected2526(lessonsSerialized);
          }
        }

        // inicializamos los valores del formulario
        setInitialValues({
          title: courseData?.title ?? "",
          description: courseData?.description ?? "",
          type: courseData?.type ?? "",
          themes: courseData?.themes ?? [],
          tags: courseData?.tags ?? [],
          axes: courseData?.axes ?? [],
          subject_id: Number(courseData?.subject_id) ?? 1,
          grade_id: courseData?.grade_id ? Number(courseData?.grade_id) : 3,
          threemester: courseData?.threemester
            ? Number(courseData?.threemester)
            : null,
          threemester_2017: courseData?.threemester_2017
            ? Number(courseData?.threemester_2017)
            : null,
          threemester_2526: courseData?.threemester_2526
            ? Number(courseData?.threemester_2526)
            : null,
          threemester_english: courseData?.threemester_english
            ? Number(courseData?.threemester_english)
            : null,
          threemester_reading: courseData?.threemester_reading
            ? Number(courseData?.threemester_reading)
            : null,
          week: courseData?.week ? Number(courseData?.week) : null,
          week_2017: courseData?.week_2017
            ? Number(courseData?.week_2017)
            : null,
          week_2526: courseData?.week_2526
            ? Number(courseData?.week_2526)
            : null,
          week_english: courseData?.week_english
            ? Number(courseData?.week_english)
            : null,
          week_reading: courseData?.week_reading
            ? Number(courseData?.week_reading)
            : null,
          sep_book_pages: courseData?.sep_book_pages
            ? courseData?.sep_book_pages
            : "",
          sep_book_pages_2017: courseData?.sep_book_pages_2017
            ? courseData?.sep_book_pages_2017
            : "",
          sep_book_pages_2526: courseData?.sep_book_pages_2526
            ? courseData?.sep_book_pages_2526
            : "",
          sep_book_pages_english: courseData?.sep_book_pages_english
            ? courseData?.sep_book_pages_english
            : "",
          sep_book_pages_reading: courseData?.sep_book_pages_reading
            ? courseData?.sep_book_pages_reading
            : "",
          lessons_2425: courseData?.lessons_2425 ?? [],
          lessons_2526: courseData?.lessons_2526 ?? [],
          lessons: courseData?.lessons ?? [],
          nem: courseData?.nem,
          hide_course_for_students: courseData?.hide_course_for_students,
          program_2017: courseData?.program_2017,
          program_2425: courseData?.program_2425,
          program_2526: courseData?.program_2526,
          english: courseData?.english,
          reading: courseData?.reading,
          month: courseData?.month ?? 1,
        });
      } else {
        setInitialValues(initialValuesObj);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [id]);

  const handleSubmit = async (values: any) => {
    let courseInput: CourseInput = {
      ...values,
      month: initialValues.nem ? initialValues.month : null,
      sep_book_pages: initialValues.sep_book_pages,
      sep_book_pages_2017: initialValues.sep_book_pages_2017,
      sep_book_pages_2526: initialValues.sep_book_pages_2526,
      sep_book_pages_english: initialValues.sep_book_pages_english,
      sep_book_pages_reading: initialValues.sep_book_pages_reading,
      threemester: initialValues.threemester,
      threemester_2017: initialValues.threemester_2017,
      threemester_2526: initialValues.threemester_2526,
      threemester_english: initialValues.threemester_english,
      threemester_reading: initialValues.threemester_reading,
      nem: initialValues.nem,
      program_2017: initialValues.program_2017,
      program_2526: initialValues.program_2526,
      english: initialValues.english,
      reading: initialValues.reading,
      week: initialValues.week,
      week_2017: initialValues.week_2017,
      week_2526: initialValues.week_2526,
      week_english: initialValues.week_english,
      week_reading: initialValues.week_reading,
      type: "course",
      subject_id: initialValues.subject_id,
      hide_course_for_students: initialValues.hide_course_for_students,
      grade_id: initialValues.grade_id,
      themes: initialValues.themes,
      axes: initialValues.axes,
      tags: initialValues.tags,
      lessons:
        initialValues.nem || initialValues.program_2526
          ? Array.from(
              new Set(
                lessonSelected2425
                  .concat(lessonSelected2526)
                  .map((lesson) => lesson.id)
              )
            ).map((id) => {
              const finalArray = lessonSelected2425.concat(lessonSelected2526);
              const finalLesson = finalArray.find((lesson) => lesson.id === id);

              return {
                ...finalLesson,
                order_by: finalLesson?.order_by ?? finalArray.length,
              };
            })
          : Array.from(new Set(lessonSelected.map((lesson) => lesson.id))).map(
              (id) => lessonSelected.find((lesson) => lesson.id === id)
            ),
      lessons_2425: initialValues.lessons_2425,
      lessons_2526: initialValues.lessons_2526,
    };

    setLoading(true);
    try {
      if (id) {
        const response = await updateCourse({
          courseInput: courseInput,
          updateCourseId: `${id}`,
        });
        response.data.updateCourse && updatedNotification();

        let index = courses.findIndex(
          (course) => course.id === response.data.updateCourse.id
        );
        let coursesUpdated = [...courses];
        coursesUpdated[index] = response.data
          .updateCourse as unknown as GetCourses_getCourses;
        dispatch({ type: "data/setCourses", payload: coursesUpdated });
        setLoading(false);
        return navigate(-1);
      } else {
        const createResponse = await createCourse(courseInput);
        createResponse && successNotification();
        let newCourses = [];
        let newCourse = createResponse.data
          .createCourse as unknown as GetCourses_getCourses;
        newCourses = [newCourse, ...courses];
        dispatch({ type: "data/setCourses", payload: newCourses });

        setLoading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      errorNotification();
      setLoading(false);
    }
  };

  const handleChangeAxes = (values: string[]) => {
    const axesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const axe = axes!.find((axis) => axis?.id === id);

      if (axe) {
        return axe;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.axes = axesValues;
    setInitialValues(initialValues);
  };

  const handleChangeThemes = (values: string[]) => {
    const themesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const selectedTheme = theme!.find((th) => th?.id === value);

      if (selectedTheme) {
        return selectedTheme;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.themes = themesValues;
    setInitialValues(initialValues);
  };

  const handleChangeTags = (values: string[]) => {
    const tagsValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const tag = tags!.find((tag) => tag?.id === id);

      if (tag) {
        return tag;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.tags = tagsValues;
    setInitialValues(initialValues);
  };

  const handleChangeLessons = (value: string[]) => {
    let arrLessonSelected: GetCourses_getCourses_lessons[] = [];
    for (let i = 0; i < value.length; i++) {
      let id: number = Number(value[i].split("-")[0]);
      let title: string = value[i].split("-")[1];
      arrLessonSelected.push({ id: `${id}`, title: title, order_by: i });
    }
    setLessonSelected(arrLessonSelected);
  };

  const handleChangeLessons2425 = (value: string[]) => {
    let arrLessonSelected: GetCourses_getCourses_lessons[] = [];
    for (let i = 0; i < value.length; i++) {
      let id: number = Number(value[i].split("-")[0]);
      let title: string = value[i].split("-")[1];
      arrLessonSelected.push({ id: `${id}`, title: title, order_by: i });
    }
    setInitialValues({
      ...initialValues,
      lessons_2425: arrLessonSelected.map((obj) => Number(obj.id)),
    });
    setLessonSelected2425(arrLessonSelected);
  };

  const handleChangeLessons2526 = (value: string[]) => {
    let arrLessonSelected: GetCourses_getCourses_lessons[] = [];
    for (let i = 0; i < value.length; i++) {
      let id: number = Number(value[i].split("-")[0]);
      let title: string = value[i].split("-")[1];
      arrLessonSelected.push({ id: `${id}`, title: title, order_by: i });
    }
    setInitialValues({
      ...initialValues,
      lessons_2526: arrLessonSelected.map((obj) => Number(obj.id)),
    });
    setLessonSelected2526(arrLessonSelected);
  };

  const axeSelectChildren: React.ReactNode[] = [];
  axes?.forEach((axe: GetAxes_getAxes, index: number) => {
    axeSelectChildren.push(
      <Option value={`${axe?.id}-${axe.name}`} key={index + `${new Date()}`}>
        {axe?.name}
      </Option>
    );
  });

  const tagSelectChildren: React.ReactNode[] = [];
  tags?.forEach((tag: GetTags_getTags, index: number) => {
    tagSelectChildren.push(
      <Option value={`${tag?.id}-${tag.name}`} key={index + `${new Date()}`}>
        {tag?.name}
      </Option>
    );
  });

  const handleChangeTrimester = (values: string[]) => {
    initialValues.threemester = Number(values[0]);
    setInitialValues(initialValues);
  };

  const handleChangeTrimester2017 = (values: string[]) => {
    initialValues.threemester_2017 = Number(values[0]);
    setInitialValues(initialValues);
  };

  const handleChangeTrimester2526 = (values: string[]) => {
    initialValues.threemester_2526 = Number(values[0]);
    setInitialValues(initialValues);
  };

  const handleChangeTrimesterEnglish = (values: string[]) => {
    initialValues.threemester_english = Number(values[0]);
    setInitialValues(initialValues);
  };

  const handleChangeTrimesterReading = (values: string[]) => {
    initialValues.threemester_reading = Number(values[0]);
    setInitialValues(initialValues);
  };

  if (
    loading ||
    loadingAxes ||
    loadingGrades ||
    loadingLessons ||
    loadingSubjects ||
    loadingThemes ||
    loadingCourses ||
    loadingTags
  ) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {id ? <h1>Editar Curso </h1> : <h1>Nuevo Curso </h1>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ errors, handleReset, touched }) => {
          return (
            <Form className={styles.formContainer}>
              <label htmlFor="course">Título</label>
              <Field
                errors={errors}
                touched={touched}
                name="title"
                title="Titulo"
                type="text"
                className={styles.formFields}
                value={initialValues.title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInitialValues({
                    ...initialValues,
                    title: e.target.value,
                  });
                }}
              />

              <label htmlFor="course">Descripción</label>
              <Row>
                <Field
                  style={{ width: "100%" }}
                  rows="4"
                  as="textarea"
                  name="description"
                  id="description"
                  className={styles.formFields}
                  value={initialValues.description}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      description: e.target.value,
                    });
                  }}
                />
              </Row>
              <ErrorMessage
                name="description"
                component="div"
                className={styles.error}
              />

              {/* PROGRAMA 2017 */}
              <Row style={{ marginBottom: 20 }}>
                <Col span={6}>
                  <label htmlFor=""> Páginas 2017</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    placeholder="Ej: 1 - 2 - 3 ..."
                    name="sep_book_pages_2017"
                    title="Páginas del libro"
                    type="text"
                    value={initialValues.sep_book_pages_2017}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        sep_book_pages_2017: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <div style={{ marginTop: 30 }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.program_2017}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          program_2017: e.target.checked,
                          month: 1,
                        });
                      }}
                    />
                    <label
                      htmlFor="program_2017"
                      style={{ marginLeft: "10px" }}
                    >
                      2017
                    </label>
                  </div>
                </Col>
                <Col span={6}>
                  <label htmlFor=""> Dosificación por semana</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="week_2017"
                    title="Dosificación por semana"
                    type="number"
                    defaultValue={initialValues.week_2017}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        week_2017: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  <label htmlFor="">Trimestre</label>
                  <Select
                    className={styles.formFields}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    defaultValue={
                      initialValues.threemester_2017
                        ? [`${Number(initialValues.threemester_2017)}`]
                        : ["1"]
                    }
                    onChange={handleChangeTrimester2017}
                    options={[
                      { value: "1", label: "1° trimestre" },
                      { value: "2", label: "2° trimestre" },
                      { value: "3", label: "3° trimestre" },
                    ]}
                  />
                </Col>
              </Row>

              {/* PROGRAMA 2425 */}
              <Row style={{ marginBottom: 20 }}>
                <Col span={6}>
                  <label htmlFor=""> Páginas 2024/2025</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    placeholder="Ej: 1 - 2 - 3 ..."
                    name="sep_book_pages"
                    title="Páginas del libro"
                    type="text"
                    value={initialValues.sep_book_pages}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        sep_book_pages: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <div style={{ marginTop: 30 }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.nem}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          nem: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor="nem" style={{ marginLeft: "10px" }}>
                      2024/2025 | NEM
                    </label>
                  </div>
                </Col>
                <Col span={6}>
                  <label htmlFor=""> Dosificación por semana</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="week"
                    title="Dosificación por semana"
                    type="number"
                    defaultValue={initialValues.week}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        week: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  <label htmlFor="">Trimestre</label>
                  <Select
                    className={styles.formFields}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    defaultValue={
                      initialValues.threemester
                        ? [`${Number(initialValues.threemester)}`]
                        : ["1"]
                    }
                    onChange={handleChangeTrimester}
                    options={[
                      { value: "1", label: "1° trimestre" },
                      { value: "2", label: "2° trimestre" },
                      { value: "3", label: "3° trimestre" },
                    ]}
                  />
                </Col>
              </Row>

              {/* PROGRAMA 2526 */}
              <Row style={{ marginBottom: 20 }}>
                <Col span={6}>
                  <label htmlFor=""> Páginas 2025/2026</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    placeholder="Ej: 1 - 2 - 3 ..."
                    name="sep_book_pages_2526"
                    title="Páginas del libro"
                    type="text"
                    value={initialValues.sep_book_pages_2526}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        sep_book_pages_2526: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <div style={{ marginTop: 30 }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.program_2526}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          program_2526: e.target.checked,
                          month: 1,
                        });
                      }}
                    />
                    <label
                      htmlFor="program_2526"
                      style={{ marginLeft: "10px" }}
                    >
                      2025/2026
                    </label>
                  </div>
                </Col>
                <Col span={6}>
                  <label htmlFor=""> Dosificación por semana</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="week_2526"
                    title="Dosificación por semana"
                    type="number"
                    defaultValue={initialValues.week_2526}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        week_2526: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  <label htmlFor="">Trimestre</label>
                  <Select
                    className={styles.formFields}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    defaultValue={
                      initialValues.threemester_2526
                        ? [`${Number(initialValues.threemester_2526)}`]
                        : ["1"]
                    }
                    onChange={handleChangeTrimester2526}
                    options={[
                      { value: "1", label: "1° trimestre" },
                      { value: "2", label: "2° trimestre" },
                      { value: "3", label: "3° trimestre" },
                    ]}
                  />
                </Col>
              </Row>

              {/* ENGLISH */}
              <Row style={{ marginBottom: 20 }}>
                <Col span={6}>
                  <label htmlFor=""> Páginas</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    placeholder="Ej: 1 - 2 - 3 ..."
                    name="sep_book_pages_english"
                    title="Páginas del libro"
                    type="text"
                    value={initialValues.sep_book_pages_english}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        sep_book_pages_english: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <div style={{ marginTop: 30 }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.english}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          english: e.target.checked,
                          month: 1,
                        });
                      }}
                    />
                    <label htmlFor="english" style={{ marginLeft: "10px" }}>
                      Inglés
                    </label>
                  </div>
                </Col>
                <Col span={6}>
                  <label htmlFor=""> Dosificación por semana</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="week_english"
                    title="Dosificación por semana"
                    type="number"
                    defaultValue={initialValues.week_english}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        week_english: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  <label htmlFor="">Trimestre</label>
                  <Select
                    className={styles.formFields}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    defaultValue={
                      initialValues.threemester_english
                        ? [`${Number(initialValues.threemester_english)}`]
                        : ["1"]
                    }
                    onChange={handleChangeTrimesterEnglish}
                    options={[
                      { value: "1", label: "1° trimestre" },
                      { value: "2", label: "2° trimestre" },
                      { value: "3", label: "3° trimestre" },
                    ]}
                  />
                </Col>
              </Row>

              {/* READING */}
              <Row style={{ marginBottom: 20 }}>
                <Col span={6}>
                  <label htmlFor=""> Páginas</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    placeholder="Ej: 1 - 2 - 3 ..."
                    name="sep_book_pages_reading"
                    title="Páginas del libro"
                    type="text"
                    value={initialValues.sep_book_pages_reading}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        sep_book_pages_reading: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <div style={{ marginTop: 30 }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.reading}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          reading: e.target.checked,
                          month: 1,
                        });
                      }}
                    />
                    <label htmlFor="reading" style={{ marginLeft: "10px" }}>
                      Lectura
                    </label>
                  </div>
                </Col>
                <Col span={6}>
                  <label htmlFor=""> Dosificación por semana</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="week_reading"
                    title="Dosificación por semana"
                    type="number"
                    defaultValue={initialValues.week_reading}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        week_reading: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  <label htmlFor="">Trimestre</label>
                  <Select
                    className={styles.formFields}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    defaultValue={
                      initialValues.threemester_reading
                        ? [`${Number(initialValues.threemester_reading)}`]
                        : ["1"]
                    }
                    onChange={handleChangeTrimesterReading}
                    options={[
                      { value: "1", label: "1° trimestre" },
                      { value: "2", label: "2° trimestre" },
                      { value: "3", label: "3° trimestre" },
                    ]}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={17}>
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.hide_course_for_students}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          hide_course_for_students: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor="reading" style={{ marginLeft: "10px" }}>
                      Esconder curso para alumnos en acceso libre
                    </label>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginBottom: 20 }}>
                <label htmlFor="axes">Eje(s)</label>
                <Select
                  mode="tags"
                  className={styles.formFieldSelect}
                  onChange={handleChangeAxes}
                  defaultValue={
                    initialValues?.axes
                      ? initialValues.axes?.map((axe, index: number) => {
                          return `${axe.id}-${axe.name}`;
                        })
                      : undefined
                  }
                >
                  {axeSelectChildren}
                </Select>
                <ErrorMessage
                  name="axes"
                  component="div"
                  className={styles.error}
                />
              </Row>

              <Row style={{ marginBottom: 20 }}>
                <label htmlFor="theme">Tema(s)</label>
                <Select
                  mode="tags"
                  className={styles.formFieldSelect}
                  defaultValue={
                    initialValues?.themes
                      ? initialValues.themes?.map((theme) => {
                          return `${theme.id}-${theme.name}`;
                        })
                      : undefined
                  }
                  onChange={handleChangeThemes}
                >
                  {theme?.map((theme: GetThemes_getThemes, index: number) => {
                    return (
                      <Option
                        value={`${theme?.id}-${theme?.name}`}
                        key={index + `${new Date()}`}
                      >
                        {theme?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Row>

              <Row style={{ marginBottom: 20 }}>
                <label htmlFor="theme">Tag(s)</label>
                <Select
                  mode="tags"
                  className={styles.formFieldSelect}
                  defaultValue={
                    initialValues?.tags
                      ? initialValues.tags?.map((tag) => {
                          return `${tag.id}-${tag.name}`;
                        })
                      : undefined
                  }
                  onChange={handleChangeTags}
                >
                  {tagSelectChildren}
                </Select>
              </Row>

              <label htmlFor="course">Asignatura</label>
              <Field
                id="subject_id"
                name="subject_id"
                as="select"
                value={initialValues.subject_id ?? 1}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInitialValues({
                    ...initialValues,
                    subject_id: Number(e.target.value),
                  });
                }}
                className={
                  errors.subject_id && touched.subject_id
                    ? styles.formFieldsError
                    : styles.formFieldSelect
                }
              >
                <option disabled value="">
                  Seleccionar
                </option>
                {subjects?.map(
                  (subject: GetSubjects_getSubjects | null, index: number) => {
                    return (
                      <option value={subject?.id} key={index}>
                        {subject?.name}
                      </option>
                    );
                  }
                )}
              </Field>
              <br />

              <label htmlFor="grade_id">Grado</label>
              <Field
                id="grade_id"
                name="grade_id"
                as="select"
                className={
                  errors.grade_id && touched.grade_id
                    ? styles.formFieldsError
                    : styles.formFieldSelect
                }
                value={initialValues.grade_id ?? 3}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInitialValues({
                    ...initialValues,
                    grade_id: Number(e.target.value),
                  });
                }}
              >
                <option disabled value="">
                  Seleccionar
                </option>
                {grade?.map(
                  (grade: GetGrade_getGrades | null, index: number) => {
                    return (
                      <option value={grade?.id} key={index}>
                        {grade?.name}
                      </option>
                    );
                  }
                )}
              </Field>
              <ErrorMessage
                name="grade_id"
                component="div"
                className={styles.error}
              />
              <br />

              {!initialValues.nem && !initialValues.program_2526 && (
                <>
                  <label htmlFor="lessons">Lecciones</label>
                  <Select
                    mode="multiple"
                    className={styles.formFieldSelect}
                    value={undefined}
                    onChange={handleChangeLessons}
                    defaultValue={
                      lessonSelected
                        ? lessonSelected?.map(
                            (
                              lesson: GetCourses_getCourses_lessons,
                              index: number
                            ) => {
                              return `${lesson.id}-${lesson.title}`;
                            }
                          )
                        : undefined
                    }
                  >
                    {lesson
                      ?.filter((lesson) => lesson.course_id === null)
                      .map((lesson, index: number) => {
                        return (
                          <Option
                            value={lesson?.id + "-" + lesson?.title}
                            key={index + `${new Date().getTime()}`}
                          >
                            {lesson?.id + " - " + lesson?.title}
                          </Option>
                        );
                      })}
                  </Select>
                  <br />
                </>
              )}

              {(initialValues.nem || initialValues.program_2526) && (
                <>
                  <div style={{ marginTop: 20 }} />
                  <label htmlFor="lessons">
                    LECCIONES PROGRAMA 2024/2025 | NEM
                  </label>
                  <Select
                    mode="multiple"
                    className={styles.formFieldSelect}
                    value={undefined}
                    onChange={handleChangeLessons2425}
                    defaultValue={
                      lessonSelected
                        ? lessonSelected
                            ?.filter((lesson) =>
                              initialValues.lessons_2425?.includes(
                                Number(lesson.id)
                              )
                            )
                            .map(
                              (
                                lesson: GetCourses_getCourses_lessons,
                                index: number
                              ) => {
                                return `${lesson.id}-${lesson.title}`;
                              }
                            )
                        : undefined
                    }
                  >
                    {lesson
                      ?.filter((lesson) => lesson.course_id === null)
                      .map((lesson, index: number) => {
                        return (
                          <Option
                            value={lesson?.id + "-" + lesson?.title}
                            key={index + `${new Date().getTime()}`}
                          >
                            {lesson?.id + " - " + lesson?.title}
                          </Option>
                        );
                      })}
                  </Select>
                  <br />
                  <div style={{ marginBottom: 20 }} />

                  <label htmlFor="lessons">LECCIONES PROGRAMA 2025/2026</label>
                  <Select
                    mode="multiple"
                    className={styles.formFieldSelect}
                    value={undefined}
                    onChange={handleChangeLessons2526}
                    defaultValue={
                      lessonSelected
                        ? lessonSelected
                            ?.filter((lesson) =>
                              initialValues.lessons_2526?.includes(
                                Number(lesson.id)
                              )
                            )
                            .map(
                              (
                                lesson: GetCourses_getCourses_lessons,
                                index: number
                              ) => {
                                return `${lesson.id}-${lesson.title}`;
                              }
                            )
                        : undefined
                    }
                  >
                    {lesson
                      ?.filter((lesson) => lesson.course_id === null)
                      .map((lesson, index: number) => {
                        return (
                          <Option
                            value={lesson?.id + "-" + lesson?.title}
                            key={index + `${new Date().getTime()}`}
                          >
                            {lesson?.id + " - " + lesson?.title}
                          </Option>
                        );
                      })}
                  </Select>
                  <br />
                  <div style={{ marginBottom: 20 }} />
                </>
              )}

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewCourses;
