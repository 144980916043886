import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  GetCourseByIdBackOffice,
  GetCourseByIdBackOfficeVariables,
} from "../__generated__/gql-types/GetCourseByIdBackOffice";

const UseGetCourseByIdBackOffice = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: GetCourseByIdBackOfficeVariables) => {
      const result: ApolloQueryResult<GetCourseByIdBackOffice> =
        await client.query({
          query: GET_COURSE_BY_ID_BACKOFFICE,
          fetchPolicy: "no-cache",
          variables,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCourseByIdBackOffice;
    },
    [client]
  );
};

export default UseGetCourseByIdBackOffice;

const GET_COURSE_BY_ID_BACKOFFICE = gql`
  query GetCourseByIdBackOffice($courseId: ID!) {
    getCourseByIdBackOffice(courseId: $courseId) {
      id
      lessons {
        title
        id
        order_by
      }
      lessons_2425
      lessons_2526
      sep_book_pages
      sep_book_pages_2017
      sep_book_pages_2425
      sep_book_pages_2526
      sep_book_pages_english
      sep_book_pages_reading
      week
      week_2017
      week_2425
      week_2526
      week_english
      week_reading
      threemester
      threemester_2017
      threemester_2425
      threemester_2526
      threemester_english
      threemester_reading
      hide_course_for_students
      subject_id
      grade_id
      month
      nem
      program_2017
      program_2425
      program_2526
      english
      reading
      axes {
        name
        id
      }
      themes {
        name
        id
      }
      deleted_at
      title
      description
      type
      publish
      subject {
        name
        id
      }
      tags {
        id
        name
      }
      grade {
        name
        id
      }
    }
  }
`;
